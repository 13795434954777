exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-personal-data-policy-index-jsx": () => import("./../../../src/pages/personal-data-policy/index.jsx" /* webpackChunkName: "component---src-pages-personal-data-policy-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-what-we-do-digital-factory-index-jsx": () => import("./../../../src/pages/what-we-do/digital-factory/index.jsx" /* webpackChunkName: "component---src-pages-what-we-do-digital-factory-index-jsx" */),
  "component---src-pages-what-we-do-mobile-app-index-jsx": () => import("./../../../src/pages/what-we-do/mobile-app/index.jsx" /* webpackChunkName: "component---src-pages-what-we-do-mobile-app-index-jsx" */),
  "component---src-pages-what-we-do-portfolio-index-jsx": () => import("./../../../src/pages/what-we-do/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-what-we-do-portfolio-index-jsx" */),
  "component---src-pages-what-we-do-web-agency-index-jsx": () => import("./../../../src/pages/what-we-do/web-agency/index.jsx" /* webpackChunkName: "component---src-pages-what-we-do-web-agency-index-jsx" */)
}

